.App {
    text-align: center;
    min-height: 100vh;
    width: 100%;
}

.centerDiv {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    max-height: min-content;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.animate-pulse {
    animation: pulse 1.5s cubic-bezier(.4, 0, .6, 1) infinite;
}

@keyframes pulse {
    50% {
        opacity: .5;
    }
}

.rotate-center {
    -webkit-animation: rotate-center 0.6s ease-in-out both;
    animation: rotate-center 0.6s ease-in-out both;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
.tableau_deploiement_filter_options:hover{
    background-color: rgba(220, 220, 220, 0.7);
}

divv {
    width: 175px;
    padding: 8px 0;
    border-radius: 6px;
    box-shadow: 0px 0px 1px rgba(62, 65, 86, 0.7), 0px 6px 12px rgba(62, 65, 86, 0.35);

    display: flex;
    flex-direction: column;

    background-color: white;
    font-size: 13px;
    font-weight: 600;
}

>div {
    padding: 6px 8px;
    color: rgba(0, 0, 0, 0.7);
}
.help-icon{
    font-size: 14px;
    margin-top: -5px;
    cursor: help;
}
